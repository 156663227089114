import TextElement from "../TextElement/TextElement";
import InputQuestion from "./InputQuestion";
import SelectQuestion from "./SelectQuestion";
import MultiSelectQuestion from "./MultiSelectQuestion";
import TextEditor from "./TextEditor";
import ToolTip from "../ToolTip";
import EndUseBreakdown from "../EndUseBreakdown/EndUseBreakdown";
import ToggleIndicator from "../Accordion/ToggleIndicator";
import TableQuestion from "./TableQuestion";
import DraggableList from "../DraggableList";
import ContentElement from "./ContentElement";

const findParentElement = (config, id) => {
  if(!id) return
  for (const e of config) {
    if(e._id === id) return e
    if(e.items) {
      const matchingElem = findParentElement(e.items, id)
      if(matchingElem) return matchingElem
    }
  }
}

// Helper function to find the previous sub_text header for an element
function findPreviousSubText(config, id) {  
  for (const index in config) {
    const elem = config[index]
    if(elem._id === id) {
      for (let i = index - 1; i >= 0; i--) {
        if (config[i].type === "sub_text") {
          return config[i];
        }
        if (config[i].type === "text") {
          break;
        }
      }
      break;
    } else if(elem.items) {
      if(elem.type === "sub_text") {
        const haschildItem = elem.items.find(item => item._id === id)
        if(haschildItem) return elem
      } else {
        const matchingElem = findPreviousSubText(elem.items, id)
        if(matchingElem) return matchingElem
      }
    }
  }
  return null;
}

function RenderContentType({
  session = {},
  validator,
  onAddressChanged,
  onUpdating,
  content,
  disableField,
  survey,
  processedConfig,
  state,
  setState,
}) {
  // Function to check if an element should be visible
  function isElementVisible(element) {
    // Headers are always visible if their parent text section is expanded
    if (element.type === "text") return true;

    const parentText = findParentElement(processedConfig, element.parentTextId);

    // If parent text has toggled and collapsed, hide everything in its section
    if (parentText?.toggle && !state.expandedSections.has(parentText._id)) {
      return false;
    }

    // For elements under a sub_text header
    if (element.type !== "sub_text") {
      const previousSubText = findPreviousSubText(processedConfig, element._id);
      if (
        previousSubText?.toggle &&
        !state.expandedSections.has(previousSubText._id)
      ) {
        return false;
      }
    }

    return true;
  }

  

  function toggleSection(sectionId) {
    const newSet = new Set(state.expandedSections);
    if (newSet.has(sectionId)) {
      newSet.delete(sectionId);
    } else {
      newSet.add(sectionId);
    }
    setState(newSet);
  }

  const isVisible = isElementVisible(content);
  const isExpanded = state.expandedSections.has(content._id);

  switch (content.type) {
    case "text":
      return (
        <div
          key={content._id}
          className={`col-lg-12 col-md-12 col-sm-12 pb-3 ${
            content.state === "HIDE" ? "d-none" : "d-block"
          }`}
          style={{ marginLeft: "-5px" }}
        >
          <div
            className="d-flex"
            style={{ cursor: content.toggle ? "pointer" : "auto" }}
            onClick={() => content.toggle && toggleSection(content._id)}
          >
            {content.toggle && (
              <ToggleIndicator
                isExpanded={isExpanded}
                type={content.toggleType || "caret"}
                className="mt-2"
                level={content.type}
              />
            )}
            <div>
              {content.title && (
                <h3>
                  <div className="d-flex">
                    <span>{content.title}</span>
                    {content.infoField && (
                      <ToolTip
                        content={<span>{content.infoField}</span>}
                        direction="right"
                      >
                        <i className="bi bi-info-circle icon"></i>
                      </ToolTip>
                    )}
                  </div>
                </h3>
              )}
              {content.body && <TextElement>{content.body}</TextElement>}
            </div>
          </div>
        </div>
      );
    case "html":
      return (
        <div
          key={content._id}
          className={`row ${
            content.state === "HIDE" || !isVisible ? "d-none" : "d-block"
          }`}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
            <h3>
              <div className="d-flex">
                <span>{content.title}</span>
                {content.infoField && (
                  <ToolTip
                    content={<span>{content.infoField}</span>}
                    direction="right"
                  >
                    <i className="bi bi-info-circle icon"></i>
                  </ToolTip>
                )}
              </div>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: content.body }} />
          </div>
        </div>
      );
    case "sub_text":
      return (
        <div
          key={content._id}
          className={`col-lg-12 col-md-12 col-sm-12 pb-3 ${
            content.state === "HIDE" || content.title === "" || !isVisible
              ? "d-none"
              : "d-block"
          }`}
          onClick={() => content.toggle && toggleSection(content._id)}
        >
          <div
            className="d-flex mt-2 mb-0"
            style={{ cursor: content.toggle ? "pointer" : "auto" }}
          >
            {content.toggle && (
              <ToggleIndicator
                isExpanded={isExpanded}
                type={content.toggleType || "caret"}
                className="mt-1"
                level={content.type}
              />
            )}
            <div>
              <h5>
                <div className="d-flex">
                  <span>{content.title}</span>
                  {content.infoField && (
                    <ToolTip
                      content={<span>{content.infoField}</span>}
                      direction="right"
                    >
                      <i className="bi bi-info-circle icon"></i>
                    </ToolTip>
                  )}
                </div>
              </h5>
              <TextElement>{content.body}</TextElement>
            </div>
          </div>
        </div>
      );
    case "input":
      return (
        <InputQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
          isVisible={isVisible}
        />
      );
    case "text_area":
      return (
        <TextEditor
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
          isVisible={isVisible}
        />
      );
    case "select":
      return (
        <SelectQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          isVisible={isVisible}
        />
      );
    case "multi_select":
      return (
        <MultiSelectQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          isVisible={isVisible}
        />
      );
    case "chart":
      return <EndUseBreakdown survey={survey} isVisible={isVisible} />;
    case "table":
      return (
        <TableQuestion
          isVisible={isVisible}
          session={session}
          content={content}
          validator={validator}
          onAddressChanged={onAddressChanged}
          onUpdating={onUpdating}
          disableField={disableField}
          survey={survey}
          processedConfig={processedConfig}
          state={state}
          setState={setState}
        />
      );
    case "draggableList": {
      return (
        <DraggableList
          key={content._id}
          content={content}
          listItems={content.items}
          session={session}
          answer={(session.answers || []).find(
            answer => answer.question === content._id
          )}
          renderListItem={item => {
            return item.hidden ? null : (
              <ContentElement
                key={`content-element-${item._id}`}
                session={session}
                validator={validator}
                onAddressChanged={onAddressChanged}
                onUpdating={onUpdating}
                content={item}
                disableField={disableField}
                survey={survey}
                processedConfig={processedConfig}
                state={state}
                setState={setState}
              />
            );
          }}
        />
      );
    }
    default:
      return null;
  }
}

export default RenderContentType;
