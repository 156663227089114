import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../DraggableList/ItemTypes";
import styles from "./DraggableListItem.css";

const DraggableListItem = ({ item, itemIndex, renderListItem, moveItem }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.LIST_ITEM,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    },
    end: elem => {
      const { toIndex } = elem;
      moveItem(item._id, itemIndex, toIndex);
    }
  });

  const [{ isOver }, drop] = useDrop(
    {
      accept: ItemTypes.LIST_ITEM,
      collect: monitor => {
        return {
          isOver: monitor.isOver()
        };
      },
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.itemIndex;
        const hoverIndex = itemIndex;
        if (dragIndex === hoverIndex) {
          return;
        }
        item.toIndex = hoverIndex;
      }
    },
    [[moveItem, itemIndex]]
  );

  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={`${styles.listItem} ${
        isOver ? styles.listItemDraggingOver : ""
      }${isDragging ? styles.listItemDragging : ""}`}
    >
      {renderListItem(item)}
      {item.items?.map(elem => renderListItem(elem))}
    </div>
  );
};

export default DraggableListItem;
